/* eslint-disable max-len */
export default {
  action: {
    add: 'Hinzufügen',
    addFile: 'Datei hinzufügen',
    addPath: 'Pfad hinzufügen',
    archive: 'Archivieren',
    back: 'Zurück',
    cancel: 'Abbrechen',
    changePassword: 'Passwort ändern',
    confirm: 'Bestätigen',
    close: 'Schliessen',
    continue: 'Fortsetzen',
    create: 'Erstellen',
    delete: 'Löschen',
    discard: 'Verwerfen',
    edit: 'Bearbeiten',
    editWhat: '{0} bearbeiten',
    help: 'Hilfsdokument herunterladen',
    irreversible: 'Diese Aktion kann nicht rückgängig gemacht werden.',
    laws: 'Gesetzesliste herunterladen',
    lock: 'Sperren',
    login: 'Login',
    logout: 'Abmelden',
    next: 'Weiter',
    postpone: 'Später lesen',
    publish: 'Veröffentlichen',
    restore: 'Wiederherstellen',
    send: 'E-Mail versenden',
    resend: 'E-Mail nochmals versenden',
    save: 'Speichern',
    search: 'Suche',
    selectAll: 'Alle auswählen',
    selectNone: 'Alle abwählen',
    start: 'Starten',
    takePicture: 'Foto aufnehmen',
    toLogin: 'Zum Login',
    transferAdmin: 'Admin-Rechte transferieren',
    unlock: 'Entsperren',
    uploadDragNDrop: 'Dateien via Drag & Drop hineinziehen',
    uploadFile: 'Datei hochladen',
    uploadFiles: 'Dateien hochladen',
    uploadPath: 'Pfad hinterlegen',
  },
  admin: {
    announcement: {
      add: 'Mitteilung hinzufügen',
      delete: {
        title: 'Mitteilung löschen',
        text: 'Sind Sie sicher, dass Sie die Mitteilung "{0}" vom {1} löschen möchten?',
      },
    },
    dashboard: {
      allCustomers: 'Alle Kunden',
      thisYear: 'Dieses Jahr',
      deletedCustomers: 'Gelöschte Kunden dieses Jahr',
      lockedCustomers: 'Gesperrte Kunden dieses Jahr',
      editedCustomers: 'Zuletzt aktualisierte Kunden',
      newCustomers: 'Neue Kunden dieses Jahr',
      numbers: {
        customers: 'Anzahl Kunden <small>(aktive, gesperrte, gelöschte)</small>',
        activeCustomers: 'Anzahl aktive Kunden',
        label: 'Anzahl',
      },
      title: 'Dashboard - Admin',
    },
    helpFiles: {
      title: 'Hilfe-Dokumente',
    },
    interview: {
      delete: {
        content: 'Sind Sie sicher, dass Sie diese Version des Themenbereichs löschen wollen?',
        title: 'Release-Version löschen',
      },
      edit: {
        addLink: 'Link hinzufügen',
        deleteQuestion: 'Frage löschen',
        deleteQuestionSure: 'Sind Sie sicher, dass Sie diese Frage löschen wollen?<br>Alle Nutzer-Antworten auf diese Frage und damit auch alle damit verknüpften Aufgaben werden ebenfalls gelöscht.',
        description: 'Kommentar',
        dragNDrop: 'Die Reihenfolge kann mit Drag & Drop angepasst werden.',
        inoperative: 'Nicht mehr in Kraft',
        liveVersionWarning: 'Sie bearbeiten gerade die aktuelle Live-Version. Alle Änderungen sind für alle Kunden unmittelbar sichtbar.',
        newQuestion: 'Neue Frage',
        nodeType: {
          branch: 'Frage zur Betroffenheit',
          comment: 'Hinweis',
          label: 'Antworttypen',
          question: 'Frage zur Erfüllung von Anforderungen',
        },
        specialSettings: 'Sonder-Eigenschaften',
        subtitle: 'Beschreibung',
        title: 'Titel',
      },
      editCurrentVersion: {
        content: 'Achtung! Diese Version des Themenbereichs ist im aktuellen Release. Wenn Sie Änderungen machen, dann sind diese sofort für alle Kunden sichtbar.',
        title: 'Aktuelle Version bearbeiten',
      },
      newVersion: {
        description: 'Hier erstellen Sie eine neue Version des Themenbereichs. Dabei wird die alte Version kopiert und kann im Anschluss bearbeitet werden.',
        label: 'Name der neuen Version (z.B. v2)',
        title: 'Neue Release-Version erstellen',
      },
      publish: {
        title: 'Themenbereich publizieren',
        content: 'Hiermit können Sie einen Themenbereich publizieren. Neu erstellte Interviews von Kunden werden danach nur noch mit Version {0} erstellt.',
      },
    },
    link: {
      add: 'Link hinzufügen',
      copied: 'Link in die Zwischenablage kopiert',
      created: 'Link erfolgreich erstellt',
      export: 'Links exportieren',
      directory: {
        add: 'Ordner hinzufügen',
        allLinks: 'Alle Links',
        create: 'Link-Verzeichnis erstellen',
        created: 'Link-Verzeichnis erfolgreich erstellt',
        edit: 'Link-Verzeichnis bearbeiten',
        label: 'Link-Verzeichnis|Link-Verzeichnisse',
        linksForDirectory: 'Link im Verzeichnis {0}',
        remove: 'Link-Verzeichnis löschen',
        removeConfirm: 'Sind Sie sicher, dass Sie das Verzeichnis "{0}" löschen wollen?',
        removeWarn: '<strong>Alle Unterverzeichnisse und Links im Verzeichnis "{0}" werden ebenfalls gelöscht!</strong>',
        title: 'Titel',
        titleDe: 'Titel (Deutsch)',
        titleFr: 'Titel (Französisch)',
      },
      deleted: 'Link erfolgreich gelöscht',
      deleteText: 'Sind Sie sicher, dass Sie den Link "{0}" löschen möchten?',
      deleteTitle: 'Link löschen',
      edit: 'Link bearbeiten',
      link: 'Link',
      linkDe: 'Link (Deutsch)',
      linkFr: 'Link (Französisch)',
      name: 'Name',
      nameDe: 'Name (Deutsch)',
      nameFr: 'Name (Französisch)',
      tags: 'Tags',
      title: 'Links',
      updated: 'Link erfolgreich bearbeitet',
    },
    topic: {
      changeName: 'Name anpassen',
      createdAt: 'Erstellt am',
      createdInterviewsCount: 'Anzahl erstellte Interviews gesamt',
      createdInterviewsYearCount: 'Anzahl erstellte Interviews dieses Jahr',
      createNewVersion: 'Neue Version erstellen',
      currentVersion: 'Aktuelle Version',
      customerStatistics: 'Kunden-Statistik',
      customersWithAccessCount: 'Anzahl Kunden mit Zugriff',
      draft: 'Entwurf',
      edit: 'Bearbeiten',
      editComment: 'Kommentar bearbeiten',
      liveVersion: 'Live Version',
      name: 'Name',
      newVersionCreated: 'Neue Version erstellt',
      overview: 'Themenbereiche',
      publish: 'Publizieren',
      updatedAt: 'Aktualisiert am',
      version: 'Version',
      versionsOfTree: 'Versionen dieses Themenbereichs',
    },
  },
  announcement: {
    activeFrom: 'Aktiv ab',
    author: 'Autor',
    file: 'Datei',
    label: 'Mitteilung|Mitteilungen',
    readAt: 'Bestätigt am',
    text: 'Text',
    title: 'Titel',
    unread: 'Ungelesen',
  },
  answer: {
    question: {
      label: 'Frage|Fragen',
    },
  },
  company: {
    address: {
      contact: 'Kontaktadresse',
      contactUpdated: 'Kontaktadresse aktualisiert',
      copiedInvoiceToContact: 'Kontaktadresse als Rechnungsadresse übernommen',
      invoice: 'Rechnungsadresse',
      invoiceUpdated: 'Rechnungsadresse aktualisiert',
    },
    admin: {
      changeAdmin: 'Admin Rechte transferieren',
      currentAdmin: 'Neuer Master User',
      helpText: 'Sie können die Admin-Rechte zu einem anderen Benutzer transferieren. Bitte beachten Sie, dass der aktuelle Administrator nach der Ausführung seine Admin-Rechte verliert.',
      transferSuccess: 'Die Admin-Rechte wurden transferiert',
    },
    city: {
      label: 'Stadt|Städte',
    },
    copyContactToInvoiceAddress: 'Kontaktadresse als Rechnungsadresse verwenden',
    country: {
      label: 'Land|Länder',
    },
    create: 'Kunde erfassen und Registrations-E-Mail versenden',
    deleted: 'Dieser Kunde wurde zusammen mit seinen Daten am {0} um {1} Uhr gelöscht.',
    deleteCompany: {
      delete: 'Kunde löschen',
      deleteHim: '{0} löschen',
      success: 'Der Kunde wird gelöscht. Sie erhalten eine E-Mail, sobald der Löschvorgang abgeschlossen ist.',
      title: 'Kunde löschen',
      helpText: 'Mit dem Löschen eines Kunden werden auch alle verknüpften Daten (Interviews, Antworten, Aufgaben, Benutzer) unwiederbringlich gelöscht.<br><br>Sind Sie sicher?',
    },
    lockCompany: {
      lock: 'Kunde sperren',
      helpText: 'Wenn Sie einen Kunden sperren, werden auch alle Benutzer des Kunden gesperrt.',
      unlock: 'Kunde entsperren',
      unlocked: 'Der Kunde wurde wieder freigeschaltet',
      lockHim: '{0} sperren',
      lockSuccess: 'Der Kunde wurde gesperrt',
      title: 'Kunde sperren',
    },
    deleteUser: {
      helpText: 'Wenn Sie einen Benutzer löschen, können Sie die zugewiesenen Aufgaben an einen anderen Benutzer weitergeben.',
      submit: '{0} löschen',
      success: 'Der Benutzer wurde gelöscht',
      title: 'Benutzer löschen',
    },
    editContactAddress: 'Kontaktadresse anpassen',
    editData: 'Adresse anpassen',
    editName: 'Firmenname anpassen',
    editInvoiceAddress: 'Rechnungsadresse anpassen',
    email: {
      label: 'E-Mail|E-Mails',
    },
    general: {
      createdAt: 'Erstelltungsdatum',
      updatedAt: 'Zuletzt bearbeitet',
      changeCompanyData: 'Rechnungsadresse anpassen',
      changeContact: 'Kontakt anpassen',
      changeMasterUser: 'Master User anpassen',
      companyData: 'Adressen',
      contact: 'Kontakt',
      createCustomer: 'Neue Nutzer hinzufügen',
      editLicense: 'Lizenz anpassen',
      label: 'Generell',
      license: 'Lizenz',
      masterUser: 'Master User',
      name: 'Firmenname',
      newCompanyName: 'Bitte geben Sie den Namen des Unternehmens an',
      or: 'oder',
    },
    contactAddress: {
      email: {
        label: 'E-Mail der Kontaktadresse|E-Mails der Kontaktadresse',
      },
    },
    invoiceAddress: {
      email: {
        label: 'E-Mail der Rechnungsadresse|E-Mails der Rechnungsadresse',
      },
    },
    license: {
      numberOfUser: 'Nutzer erfasst / verfügbar',
      numberOfOus: 'Standorte erfasst / verfügbar',
      telNumber: 'Telefon-Nr.',
      email: 'E-Mail',
      changeHelpText: 'Falls die neue Lizenz weniger Nutzer umfasst als die alte Lizenz, werden die Benutzer gesperrt.',
      plan: 'Lizenzmodel',
    },
    lockUser: {
      helpText: 'Sie können einen Benutzer sperren. Ein gesperrter Benutzer hat keinen Zugriff auf die Plattform. Wenn Sie einen Benutzer sperren, können Sie die zugewiesenen Aufgaben an einen anderen Benutzer weitergeben.',
      licenseContingentReached: 'Das Benutzerkontingent der Lizenz ist ausgeschöpft',
      selectUser: 'Wählen Sie einen Benutzer',
      submit: '{0} sperren',
      success: 'Der Benutzer wurde gesperrt',
      title: 'Benutzer sperren',
      transferTasksTo: 'Aufgaben zuweisen',
    },
    overview: {
      allTopics: 'Alle Themenbereiche (Zusammenfassung)',
      label: 'Gesamtübersicht',
      numberOfFinishedInterviews: 'Fertige Interviews',
      totalNumberOfInterviews: 'Erstellte Interviews',
    },
    pendingMutation: 'Ungeprüfte Mutation',
    postalCode: {
      label: 'PLZ',
    },
    showDeletedOnly: 'Nur gelöschte Kunden anzeigen',
    settings: {
      completeData: 'Bitte vervollständigen Sie hier Ihre Firmenangaben. Danach können Sie mit der Nutzung von COMPLYANT beginnen.',
      error: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später nochmals.',
      interviewAnswerCreateTaskWarning: 'Erinnerung an Aufgabenerstellung bei nicht erfüllten Fragen einblenden',
      interviewAnswerSuggestClarificationTask: 'Hinweis zur Erstellung einer Aufgabe einblenden, wenn eine Frage mit “Detailabklärung” beantwortet wird',
      interviewAnswerEmptyCommentWarning: 'Warnung bei leerem Kommentar in Fragen einblenden',
      sendTaskMails: 'E-Mail versenden, wenn eine Aufgabe erstellt oder beantwortet wird',
      mfaEnforced: 'Erzwinge das Einrichten der Zwei-Faktor-Authentifizierung für alle Nutzer',
      mfaEnforcedAdminNeedsIt: 'Sie müssen zuerst in Ihrem Profil die Zwei-Faktor-Authentifizierung aktivieren, bevor Sie sie firmenweit erzwingen können.',
      success: 'Die Einstellung wurde übernommen.',
      title: 'Einstellungen',
    },
    street: {
      label: 'Strasse + Hausnr.|Strassen + Hausnr.',
    },
    street2: {
      label: 'Adresszeile 2',
    },
    unlockDelete: 'Entsperren / Löschen',
    user: {
      name: 'Name',
      accessTo: 'Zugriff auf',
      adminAccess: 'Alle (Administrator)',
      allAccess: 'Alle',
      label: 'Benutzer',
      locked: 'Gesperrt',
    },
  },
  chart: {
    allQuestions: 'Alle Fragen',
    answeredQuestions: 'Beantwortete Fragen',
    'Nicht beantwortet': 'Nicht beantwortet',
    Beantwortet: 'Beantwortet',
    'Alle Fragen': 'Alle Fragen',
    'Alle Standorte': 'Alle Standorte',
    'Beantwortete Fragen': 'Beantwortete Fragen',
    'Nicht relevant': 'Nicht relevant',
    'Erfüllt - kein Handlungsbedarf': 'Erfüllt - kein Handlungsbedarf',
    'Erfüllt mit Auflagen': 'Erfüllt mit Auflagen',
    'Detailabklärungen erforderlich': 'Detailabklärungen erforderlich',
    'Nicht erfüllt': 'Nicht erfüllt',
    notApplicable: 'n.a.',
  },
  customer: {
    company: {
      label: 'Firma',
      purgedAt: 'Gelöscht am',
    },
    createTitle: 'Kunde hinzufügen',
    descriptionText: 'Sie können Kunden hinzufügen oder die Rollen bestehender Benutzer anpassen.',
    exportTitle: 'Kundenliste exportieren',
    exportAllTitle: 'Kundenliste exportieren (alle Benutzer)',
    label: 'Kunde|Kunden',
    licenseType: {
      label: 'Lizenztyp',
    },
    name: {
      label: 'Name',
    },
    pendingMutation: {
      label: 'Neue Änderungen',
    },
    updatedAt: {
      label: 'Letzte Aktualisierung',
    },
  },
  error: {
    account_locked: 'Ihr Account wurde gesperrt. Bitte wenden Sie sich an den Support.',
    failedToCreateAnswer: 'Fehler beim Vorbereiten der Antwort. Bitte laden Sie die Seite neu.',
    invalid_code: 'Zwei-Faktor-Code ist ungültig',
    invalid_credentials: 'Ungültige Zugangsdaten',
    search: 'Fehler beim Suchen. Bitte versuchen Sie es erneut.',
    failedToSave: 'Fehler beim Speichern: {0}',
    unknownError: 'Es ist ein unbekannter Fehler aufgetreten',
    unknownTryAgain: 'Es ist Fehler aufgetreten. Bitte versuchen Sie es später nochmals.',
    exceededNumberOfPrototypes: 'Pro Standort und Themenbereich darf es jeweils nur eine Vorlage geben',
    page_not_found: 'Diese Seite existiert nicht.',
    export: 'Fehler beim Exportieren. Bitte versuchen Sie es später nochmals.',
    fileTooLarge: 'Die Datei ist zu gross. Bitte wählen Sie eine kleinere Datei. (Max. 10MB)',
    fieldInUse: 'Momentan durch anderen Benutzer in Bearbeitung!',
    recordInUse: 'Momentan durch anderen Benutzer in Bearbeitung!',
  },
  export: {
    button: 'Export',
    loadingInfo: 'Der Export kann je nach Grösse einige Minuten dauern, bitte haben Sie etwas Geduld.',
    chooseInterview: 'Welche Themen sollen berücksichtigt werden?',
    chooseCopyInterview: 'Welche Themen sollen kopiert werden?',
    chooseOrganization: 'Bitte wählen Sie Ihren Standort aus',
    columns: {
      answer: 'Antwort',
      comment: 'Bemerkung',
      description: 'Kommentar',
      files: 'Dateien',
      label: 'Spalten',
      name: 'Name',
      question: 'Frage',
    },
    documentName: 'Dokumentname',
    year: 'Bitte wählen Sie das Jahr aus',
    topic: 'Bitte wählen Sie den Themenbereich aus',
    documentType: {
      label: 'Bitte wählen Sie die Dokumentart aus',
      long: 'Nachweis-Dossier - Fragen/Antworten, inkl. Gesetzestext (PDF)',
      progress: 'Compliance-Status',
      short: 'Nachweis-Dossier - nur Fragen/Antworten (PDF)',
      interview: 'Nachweis-Dossier - unformatiert (Excel)',
      links: 'Integrierte Links - Liste (Excel)',
      documents: 'Eigene Links - Liste (Excel)',
    },
    exportType: {
      csv: 'Als CSV-Datei (Semikolon)',
      excel: 'Als Excel-Datei',
      label: 'Dateiformat',
      pdf: 'Als PDF-Datei',
    },
    filter: {
      clarification: 'Detailabklärungen erforderlich',
      cond_fulfilled: 'Erfüllt - mit Auflagen',
      fulfilled: 'Erfüllt - kein Handlungsbedarf',
      irrelevant: 'Nicht relevant',
      label: 'Filter',
      not_fulfilled: 'Nicht erfüllt',
      relevant: 'Alle relevanten Fragen / Knoten',
      new_updated: 'Neue/aktualisierte Fragen',
    },
    title: 'Export',
    progressOverview: 'Umwelt + Sicherheit',
    interviewsNotFormatted: 'Interivews unformatiert',
    downloadName: 'Gewünschter Titel',
  },
  file: {
    label: 'Datei|Dateien',
    path: 'Dateipfad',
  },
  global: {
    cancel: 'Abbrechen',
    createdAt: {
      label: 'Erstelldatum|Erstelldaten',
    },
    delete: 'Unwiederbringlich löschen',
    deleted: 'Gelöscht',
    deletedIt: '{0} wurde gelöscht.',
    demoMode: 'Demo-Modus! Nicht für produktiven Einsatz geeignet!',
    displayLanguage: 'Anzeigesprache',
    imageFileFormat: 'Bild im JPEG-, PNG- oder GIF-Format',
    impersonating: 'Sie sind als {0} {1} eingeloggt.',
    language: 'Sprache',
    no: 'Nein',
    none: 'Keine',
    noResults: 'Keine Ergebnisse',
    oclock: 'Uhr',
    or: 'oder',
    simultaneousEditingWarning: 'Bitte beachten Sie, dass das Bearbeiten desselben Interviews innerhalb desselben Standortes von mehreren Nutzern gleichzeitig nicht unterstützt ist!',
    searchByFullName: 'Suche nach vollständigen Namen',
    stopImpersonating: 'Zurück zum Admin',
    typeToSearch: 'Zum Suchen tippen',
    unknownError: 'Es ist ein unbekannter Fehler aufgetreten',
    updatedAt: {
      label: 'Aktualisiert am',
    },
    yes: 'Ja',
    search: 'Suchen',
    searchInterviews: 'Interviews durchsuchen',
    languageValue: {
      de: 'Deutsch',
      fr: 'Französisch',
    },
  },
  interview: {
    askForTask: {
      title: 'Aufgabe erstellen?',
      text: 'Sie haben angegeben, dass Detailabklärungen erforderlich sind. Wollen Sie direkt eine Aufgabe erstellen?',
    },
    answer: {
      clarification: 'Detailabklärungen',
      clarificationNeeded: 'Detailabklärungen erforderlich',
      comment: 'Bemerkung',
      conditionallyFulfilled: 'Erfüllt - Mit Auflagen',
      containsNewQuestions: 'Beinhaltet neue Fragen',
      done: 'Gemacht',
      fulfilled: 'Erfüllt',
      fulfilledNoActionNeeded: 'Erfüllt - Kein Handlungsbedarf',
      irrelevant: 'Nicht relevant',
      label: 'Antwort|Antworten',
      new: 'Neu/Aktualisiert',
      notFulfilled: 'Nicht erfüllt',
      open: 'Noch offen',
      yes: 'Ja',
      no: 'Nein',
      noTooltip: 'Export ins Nachweis-Dossier',
      questionNotRelevant: 'Frage nicht relevant',
      questionNotRelevantTooltip: 'kein Export ins Nachweis-Dossier',
    },
    answerDegree: 'Beantwortungsgrad',
    branch: {
      label: 'Knoten|Knoten',
    },
    bulkUpdate: 'Interviews aktualisieren',
    bulkUpdateDescription: 'Möchten Sie die nachfolgenden Themenbereiche auf die neuste Version aktualisieren?',
    bulkUpdateDescriptionAll: 'Möchten Sie die nachfolgenden Themenbereiche <strong>in allen Standorten</strong> auf die neuste Version aktualisieren?',
    comment: {
      label: 'Kommentar|Kommentare',
      showInReport: 'Hinweis im Report anzeigen',
    },
    commentNotification: 'Sie haben noch keine Bemerkung verfasst. Bitte verfassen Sie eine Bemerkung oder klicken Sie erneut auf "Weiter".',
    copied: 'Interview erfolgreich zum Standort {0} kopiert.',
    delete: {
      title: 'Interview löschen',
      text: 'Möchten Sie das Interview wirklich löschen?<br><strong>Das Interview, alle gegebenen Antworten und alle verknüpften Aufgaben werden <em>unwiederbringlich entfernt</em>!</strong>',
    },
    degreeOfFulfillment: 'Erfüllungsgrad (beantwortete Fragen)',
    document: {
      label: 'Dokument|Dokumente',
    },
    filter: {
      clarification: 'Detailabklärungen erforderlich',
      cond_fulfilled: 'Erfüllt - mit Auflagen',
      fulfilled: 'Erfüllt - kein Handlungsbedarf',
      highPriority: 'Hohe Priorität',
      inoperative: 'Ausser Kraft',
      irrelevant: 'Nicht relevant',
      not_fulfilled: 'Nicht erfüllt',
      open: 'Nicht beantwortet',
      relevant: 'Relevante Fragen',
      notAnswered: 'Nicht beantwortete Fragen',
      title: 'Filtermöglichkeiten & Visualisierung',
      updated: 'Neue/aktualisierte Fragen',
      nothingFound: 'Keine Fragen mit dem Filter "{0}" gefunden.',
    },
    finished: 'Sie haben alle gem. Filter ausgewählten Fragen beantwortet.',
    fromEmpty: 'Leer',
    fromPrevYear: 'Aus Vorversion',
    fromPrototype: 'Aus Vorlage',
    fulfilmentState: 'Erfüllungsstatus',
    irrelevantParent: 'Die assoziierte Hauptfrage wurde als nicht relevant markiert.',
    inoperativeParent: 'Die assoziierte Hauptfrage wurde als nicht in Kraft markiert.',
    inoperativeQuestion: 'Die Frage ist ausser Kraft.',
    label: 'Interviewname|Interviewnamen',
    name: {
      label: 'Interviewname',
    },
    newText: 'Bitte wählen Sie die gewünschten Themenbereiche aus:',
    noPrevInterview: 'Themenbereich nicht im Vorjahr ausgefüllt',
    noPrototypeInterview: 'Keine Vorlage vorhanden',
    overview: 'Interviewübersicht',
    question: {
      label: 'Frage|Fragen',
      updatedAt: 'Letzte Änderung',
    },
    state: {
      clarification: 'Detailabklärungen',
      completed: 'Fertig',
      label: 'Bearbeitungs-Status',
    },
    gradeOfFulfillment: {
      label: 'Erfüllungsgrad',
    },
    taskNotification: 'Sie haben mit "Detailabklärungen erforderlich" geantwortet. Wollen Sie noch eine Aufgabe erstellen? Falls nicht, klicken Sie erneut auf "Weiter".',
    templateForTopic: 'Themenbereich',
    templateUpdatedAt: 'Zuletzt bearbeitet am',
    unansweredParent: 'Die assoziierte Hauptfrage wurde noch nicht beantwortet.',
    version: 'Release-Version',
    year: 'Jahr',
    yearSelect: {
      label: 'Jahrauswahl',
    },
    copyTo: 'Kopieren nach ...',
    copy: 'Kopieren',
    copyNoTopics: 'Keine Themen zum kopieren vorhanden.',
    update: 'Update von {0}',
    updateDescription: 'Möchten Sie das bestehende Interview auf die Version "{0}" aktualisieren?',
    updateSuccess: 'Das Interview wurde erfolgreich auf den neusten Stand gebracht.',
    updateError: 'Das Interview konnte nicht auf den neusten Stand gebracht werden.',
    searchOnlyAnswers: 'Nur eigene Antworten durchsuchen',
    enterSearch: 'Suchbegriff eingeben',
    search: 'Interview-Fragen durchsuchen',
    noResult: 'Keine Ergebnisse',
    expandCollapseButton: 'Alle Fragen auf-/einklappen',
    copyDisabled: 'Interview ist nicht auf der neusten Version.',
    exportDisabled: 'Interview ist nicht auf der neusten Version.',
    prototypeTitle: 'Vorlage für',
    updateVersion: {
      keepVersion: 'Ja, aktualisieren (Vorversionen behalten)',
      updateVersion: 'Ja, aktualisieren (keine Vorversionen behalten)',
    },
  },
  license: {
    accessToTopics: 'Welche Themen sollen inkludiert sein?',
    add: 'Lizenztyp hinzufügen',
    allowedUsers: 'Erlaubte # Benutzer',
    allowedOus: 'Erlaubte # Standorte',
    create: 'Neue Lizenz erstellen',
    created: 'Neue Lizenz wurde erstellt',
    delete: {
      delete: 'Lizenz löschen',
      helpText: 'Eine gelöschte Lizenz kann keinem Nutzer mehr hinzugefügt werden.',
      submit: '{0} löschen',
      success: 'Die Lizenz wurde gelöscht',
      title: 'Lizenz löschen',
    },
    editNumberOfUsers: 'Anzahl erlaubte Benutzer',
    editNumberOfOrganizationalUnits: 'Anzahl erlaubte Standorte',
    helpNegativeEqualsUnlimited: 'Ein Wert von -1 bedeutet eine unbegrenzte Anzahl.',
    label: 'Lizenz|Lizenzen',
    name: 'Name',
    numberOfAssigned: 'Anzahl zugewiesener Kunden',
    topics: 'Themen',
    updated: 'Lizenz erfolgreich aktualisiert!',
  },
  login: {
    mfa: {
      description: '<p>Um die Zwei-Faktor-Authentisierung einzurichten, laden Sie sich bitte den Google Authenticator oder eine andere TOTP-App auf Ihr Smartphone. Scannen Sie dazu den folgenden QR-Code mit Ihrer App.</p>',
      descriptionGoogle: '<p>Geben Sie nun den sechsstelligen Code aus Ihrer Authentisierungs-App hier ein und klicken Sie auf die Schaltfläche "Bestätigen."</p>',
      success: 'Zwei-Faktor-Authentifizierung erfolgreich eingerichtet! Sie können sich nun einloggen.',
      title: 'Zwei-Faktor-Authentifizierung aktivieren',
      titleGoogle: 'Google Authenticator 2FA Check',
      confirm: 'Ich bestätige, dass ich 2FA zu meiner Google Authenticator App hinzugefügt habe.',
    },
  },
  network: {
    backOnline: 'Sie sind wieder online',
    offline: 'Sie sind offline',
  },
  onboarding: {
    title: 'Initiale Einrichtung',
    prev: 'Zurück',
    next: 'Weiter',
    finish: 'Mit der Nutzung von COMPLYANT starten',
    success: {
      title: 'Einrichtung erfolgreich abgeschlossen',
      text: '<p class="mb-4">Klicken Sie unten auf "Weiter", um zur Startseite zu gelangen. Dort können Sie neue Interviews erstellen oder bestehende bearbeiten.</p><p class="mb-4">Die Einstellungen erreichen Sie künftig über das Zahnrad-Symbol oben rechts, ebenso wie Ihre persönlichen Daten und Einstellungen, wie die bevorzugte Sprache.',
    },
  },
  organizationalUnit: {
    label: 'Standort|Standorte',
    labelAccess: 'Zugriff auf',
    name: {
      label: 'Name',
    },
  },
  profile: {
    title: 'Benutzerprofil',
    password: 'Passwort',
    passwordReset: 'Passwort zurücksetzen',
    passwordResetSuccess: 'Wir haben Ihnen eine E-Mail zum Zurücksetzen Ihres Passworts geschickt. Sie werden nun ausgeloggt.',
    platformLanguage: 'Sprache der Plattform',
    resetTwoFactorSetup: 'Zwei-Faktor-Authentifizierung zurücksetzen',
    role: 'Rolle',
    twoFactor: 'Zwei-Faktor Authentifizierung',
    twoFactorReset: 'Zwei-Faktor-Authentifizierung zurücksetzen',
    twoFactorSetup: 'Zwei-Faktor-Authentifizierung einrichten',
    twoFactorText: 'Hier können Sie die Zwei Faktor-Authentifizierung managen. <br>Mit Hilfe der Zwei Faktor-Authentifizierung können Sie Ihren Account sicherer machen und vor unbefugten Verwendungen schützen.',
    mfa: {
      backToProfile: 'Zurück zum Profil',
      code: '2FA Code',
      checkCode: 'Code überprüfen',
      disableButton: 'Zwei-Faktor-Authentifizierung deaktivieren',
      disable: 'Deaktivieren',
      intro: 'Hier können Sie die Zwei-Faktor-Authentifizierung einrichten.',
      introCompleted: 'Sie haben die Zwei-Faktor-Authentifizierung bereits eingerichtet. Hier können Sie die Zwei-Faktor-Authentifizierung zurücksetzen und neu einrichten.',
      introCompletedDisable: 'Sie haben die Zwei-Faktor-Authentifizierung bereits eingerichtet. Hier können Sie die Zwei-Faktor-Authentifizierung zurücksetzen und neu einrichten oder komplett deaktivieren.',
      loginWithCode: 'Bitte geben Sie den Code aus Ihrer Authentisierungs-App ein.',
      resetButton: 'Zwei-Faktor-Authentifizierung erneut einrichten',
      resetText: 'Hier können Sie die Zwei-Faktor-Authentifizierung zurücksetzen.<br><strong>Achtung: Alle bisher eingerichteten Zwei-Faktor-Authentisierungs-App müssen erneut eingerichtet werden!</strong>',
      disableText: 'Geben Sie zum Deaktivieren der Zwei-Faktor-Authentifizierung den Code aus Ihrer Authentisierungs-App ein. Sollten Sie keinen Zugriff mehr auf Ihren Authentifizierungs-Code haben, melden Sie sich bitte bei unserem Support.',
      scan: 'Bitte fügen Sie COMPLYANT zu Ihrer Google Authenticator (oder anderer TOPT)-App hinzu. Scannen Sie dazu den folgenden QR-Code mit Ihrer App.<br>Geben Sie anschliessend den angezeigen sechsstelligen Code in das Feld ein.',
      setupButton: 'Einrichten',
      success: 'Zwei-Faktor-Authentifizierung erfolgreich eingerichtet.',
      successDisabling: 'Zwei-Faktor-Authentifizierung wurde deaktiviert.',
    },
  },
  question: {
    label: 'Frage|Fragen',
  },
  settings: {
    billing: {
      helpText: 'Benötigen Sie weitere Lizenzen? Falls ja, nehmen Sie bitte mit uns Kontakt auf.',
      overview: 'Übersicht',
      title: 'Lizenzinformationen',
    },
    company: {
      created: 'Neues Kundenkonto angelegt.',
    },
    companyData: {
      title: 'Stammdaten',
    },
    import: {
      apiToken: 'Ihr API-Schlüssel:',
      apiTokenDescription: 'Der Migrationsassistent wird Sie nach diesem API-Schlüssel fragen. Am besten kopieren Sie ihn daher in die Zwischenablage.',
      checkForJobsText: 'Wenn Sie die Migration durchgeführt haben, klicken Sie auf den Button unten, um den Status zu überprüfen und anschliessend fortzufahren.',
      checkForJobs: 'Auf durchgeführte Migration prüfen',
      downloadText: 'Laden Sie hier den Migrationsassistenten herunter. Dieser wird Ihnen helfen, Ihre Daten aus Ihrem alten System in COMPLYANT zu importieren.',
      download: 'Migrationsassistenten für LCS.pro herunterladen',
      downloadLcsNet: 'Migrationsassistenten für LCS.net herunterladen',
      label: 'Import',
      intro: 'Mit Hilfe unseres Migrationsassistenten können Sie Ihre Daten aus Ihrer bisherigen LCS.pro- oder LCS.net-Installation einfach in COMPLYANT importieren. Laden Sie sich dazu den Migrationsassistenten herunter und folgen Sie den Anweisungen.',
      migrationDescription: 'Laden Sie über den folgenden Link den Migrationsassistenten für LCS.pro herunter. Nach dem Download müssen Sie das ZIP-Archiv entpacken und die Datei "setup.exe" ausführen.',
      migrationDescriptionNetwork: 'Über den folgenden Link können Sie den Migrationsassistenten für LCS.net herunterladen. Nach dem Download müssen Sie das ZIP-Archiv entpacken und die Datei "setup.exe" auf einen Computer mit Zugriff auf die LCS.net-Datenbank ausführen.',
      noJobsFound: 'Es wurden keine durchgeführten Migrationsjobs gefunden. Falls Sie den Migrationsassitenten bereits ausgeführt hatten, versuchen Sie es bitte in einer Minute nochmals.',
      jobsFound: 'Die Migration wurde erfolgreich durchgeführt. Sie können nun mit COMPLYANT fortfahren.',
      link: 'Ihr Download-Link für den Migrationsassistenten:',
      skip: 'Ohne Migration mit COMPLYANT starten',
      onboarding: {
        download: 'Zum Migrationsassistenten',
        empty: 'Frisch starten',
        text: 'Jetzt haben Sie die Möglichkeit, bestehende Daten von LCS.pro oder LCS.net zu importieren. Falls Sie lieber von Null an starten wollen oder nichts zu importieren haben, können Sie stattdessen auch mit neuen Daten starten.',
      },
    },
    organizationalUnits: {
      addUnit: 'Standort hinzufügen',
      created: 'Standort erfolgreich erstellt',
      delete: 'Standort löschen',
      deleted: 'Standort erfolgreich gelöscht',
      disabledTheme: 'Standort-spezifisches Design deaktiviert',
      finishOnboarding: 'Standorte erfasst',
      intro: 'Sie können neue Standorte hinzufügen oder bestehende anpassen.',
      introNoOu: 'Sie können bestehende Standorte anpassen und ihr spezifisches Design anpassen.',
      new: 'Neuer Standort',
      onboarding: '<p>Hier können Sie neue Standorte hinzufügen oder bestehende anpassen.</p><p>Vielleicht wollen Sie den initialen Standort umbenennen? Klicken Sie dazu auf das Bearbeiten-Symbol unten in der Tabelle.</p><p>Wenn die Standorte stimmen, klicken Sie bitte auf "Standorte erfasst".',
      sureToDelete: 'Sind Sie sicher, dass Sie diesen Standort löschen möchten? <strong>Alle mit diesem Standort verknüpften Interviews, Aufgaben und Vorlagen werden ebenfalls gelöscht!</strong>',
      title: 'Standorte',
      updated: 'Standort erfolgreich aktualisiert',
      saveTheme: 'Standort-spezifisches Design erfolgreich aktualisiert',
    },
    prototype: {
      chooseTopic: 'Bitte wählen Sie den gewünschten Themenbereich aus',
      create: 'Vorlage erstellen',
      intro: 'Sie können neue Vorlagen erstellen oder bestehende anpassen. Pro Standort und Themenbereich darf es jeweils nur eine Vorlage geben.',
      label: 'Vorlage|Vorlagen',
      organizationalUnits: 'Die Vorlage gilt für die folgenden Standorte',
      delete: {
        title: 'Vorlage löschen',
        text: 'Möchten Sie die Vorlage wirklich löschen?',
      },
    },
    theme: {
      backgroundImage: 'Hintergrundbild',
      backgroundImage_ou: 'Hintergrundbild für diesen Standort',
      colorFont: {
        label: 'Schriftfarbe',
      },
      colorMain: {
        label: 'Hauptfarbe',
        label_ou: 'Hauptfarbe für diesen Standort',
      },
      colorSecondary: {
        label: 'Sekundärfarbe',
      },
      enableOuSpecificTheme: 'Standort-spezifisches Design aktivieren',
      font: {
        label: 'Schriftart',
      },
      intro: 'Sie können Brandingelemente sowie das Logo anpassen. Diese werden zur Erstellung der PDF-Dateien benutzt.',
      label: 'Design|Designs',
      logo: 'Logo',
      logo_ou: 'Logo für diesen Standort',
      ouTitle: 'Design für Standort',
      saved: 'Design erfolgreich gespeichert',
      saveText: 'Die Änderungen werden bei allen neu erstellten PDFs berücksichtigt.',
    },
    userManagement: {
      addUser: 'Benutzer hinzufügen',
      created: 'Benutzer erfolgreich erstellt',
      delete: 'Benutzer löschen',
      deleted: 'Benutzer erfolgreich gelöscht',
      emailNotVerified: 'Noch nicht bestätigt',
      finishOnboarding: 'Zu den Interviews',
      intro: 'Sie können Benutzer hinzufügen oder die Rollen bestehender Benutzer anpassen.',
      lockedUser: 'Benutzer erfolgreich gesperrt',
      onboarding: 'Der Start ist geschafft! Hier können Sie noch Ihre Benutzer erfassen oder bearbeiten. Wenn Sie fertig sind, können Sie zu den Interviews gehen und richtig loslegen!',
      resetPassword: 'Password zurücksetzen',
      resetPasswordSuccess: 'Passwort-Reset-E-Mail versendet',
      sureToDelete: 'Sind Sie sicher, dass Sie den Benutzer "{0}" löschen wollen?',
      sureToResetPassword: 'Sind Sie sicher, dass Sie den Passwort-Vergessen-Vorgang für den Benutzer "{0}" starten wollen? Die Person wird eine E-Mail mit weiteren Schritten erhalten.',
      sureToTransferAdmin: 'Ihre Admin-Rechte werden an den Benutzer "{0}" übertragen und Ihnen entzogen. Sie werden im Anschluss auf die Startseite weitergeleitet und haben danach hier keinen Zugriff mehr. Sind Sie sicher, dass Sie das wollen?',
      sureToTransferAdminAsPO: 'Die Admin-Rechte werden an den Benutzer "{0}" übertragen und dem bisherigen Admin entzogen. Beide betroffenen Nutzer werden per E-Mail über die Änderung informiert.',
      title: 'Benutzerverwaltung',
      transferAdmin: 'Adminrechte übertragen',
      unlockedUser: 'Benutzer erfolgreich freigegeben',
      updated: 'Benutzer erfolgreich aktualisiert',
      removeTwoFA: '2-Faktor Authentifizierung entfernen',
      transferAdminSuccess: 'Adminrechte erfolgreich übertragen',
      removeTwoFASuccess: '2-Faktor Authentifizierung erfolgreich entfernt',
    },
  },
  task: {
    answer: 'Beantworten',
    author: {
      label: 'Erstellt von',
    },
    copyAnswer: 'Antwort in Frage-Bemerkung kopieren',
    createdAt: {
      label: 'Erstellt am',
    },
    export: {
      title: 'Aufgaben exportieren',
      fileName: 'Aufgabenliste.xlsx',
      assignee: 'Zugewiesen an',
      topic: 'Themenbereich',
    },
    editTask: 'Aufgabe bearbeiten',
    archiveTask: 'Aufgabe archivieren',
    assignee: {
      formLabel: 'Bitte geben Sie die Email der zuständigen Person an',
      label: 'Zuständige Person|Zuständige Personen',
      none: '–',
      noValidEmail: 'Das ist keine gültige Email',
    },
    createTitle: 'Aufgabe erstellen',
    deleteTask: 'Aufgabe löschen',
    description: {
      label: 'Beschreibung|Beschreibungen',
      labelLong: 'Aufgabenbeschreibung|Aufgabenbeschreibung',
    },
    dueDate: {
      label: 'Fällig bis|Fällig bis',
      none: 'Keines',
      error: 'Fälligkeitsdatum darf nicht leer sein',
      warningLabel: 'Fälligkeit',
      today: 'Heute',
    },
    includeArchived: 'Inkl. archivierte Aufgaben',
    label: 'Aufgabe|Aufgaben',
    markCheck: 'Zum Überprüfen markieren',
    markDone: 'Als fertig markieren',
    name: {
      label: 'Aufgabenname|Aufgabennamen',
    },
    navigateToQuestion: 'Zur Frage',
    noneYet: 'Bisher keine Aufgaben',
    overview: 'Aufgabenübersicht',
    recurring: {
      label: 'Wiederkehrend',
      willRecur: 'Wird in nächstjährige Iteration kopiert',
      helpText: 'Wiederkehrende Aufgaben werden kopiert und erneut erstellt, wenn ein neues Interview aus Vorjahresdaten erstellt wird.',
    },
    resolution: {
      label: 'Antwort|Antworten',
      none: 'Keine',
    },
    saved: 'Aufgabe wurde gespeichert',
    sendEmail: { success: 'E-Mail versendet' },
    showDeletedTasks: 'Archivierte Aufgaben anzeigen',
    showOwnTasksOnly: 'Nur meine Aufgaben anzeigen',
    state: {
      check: 'Überprüfung',
      done: 'Fertig',
      label: 'Bearbeitungs-Status|Bearbeitungs-Status',
      new: 'Offen',
      notsent: 'Ungesendet',
      archived: 'Archiviert',
    },
    sureToArchive: 'Sind Sie sicher, dass Sie die Aufgabe archivieren wollen?',
    sureToDelete: 'Sind Sie sicher, dass Sie die Aufgabe löschen wollen?',
    titleForQuestion: 'Aufgaben für diese Frage',
    unarchive: 'Archivierung rückgängig machen',
    viewAllTasks: 'Alle Aufgaben ansehen',
  },
  title: {
    adminDashboard: 'Dashboard',
    announcements: 'Mitteilungen',
    customers: 'Kunden',
    interview: 'Interview',
    interviews: 'Interviews',
    interviewQuestions: 'Themenbereich',
    licenses: 'Lizenzpakete',
    links: 'Links',
    login: 'Login',
    onboarding: 'Onboarding',
    profile: 'Profil',
    resetPassword: 'Passwort zurücksetzen',
    setPassword: 'Passwort setzen',
    settings: 'Einstellungen',
    tasks: 'Aufgaben',
    template: 'Themenbereich bearbeiten',
    topic: 'Thema',
    topicOverview: 'Themenbereich',
    totalOverview: 'Gesamtübersicht',
    userSettings: 'Benutzereinstellungen',
  },
  tooltip: {
    createInterview: 'Interviews erstellen',
    interviewOverview: 'Interview-Übersicht',
    tasks: 'Aufgaben',
    announcements: 'Mitteilungen',
    export: 'Compliance-Status und Interviews exportieren',
    exportCompanyOverview: 'Export',
    exportTasks: 'Aufgaben exportieren',
    markFavorite: 'Frage als hohe Priorität markieren',
    exportOne: 'Interview exportieren',
    updateInterview: 'Interview auf neue Version aktualisieren | Interviews auf neue Version aktualisieren',
    copy: 'Interviews zu anderen Standorten kopieren',
    copyOne: 'Interview zu anderen Standorten kopieren',
    deleteInterview: 'Interview löschen',
    settings: 'Einstellungen',
    customers: 'Kunden',
    links: 'Links',
    licenses: 'Lizenzpakete',
    deleteTemplate: 'Version löschen',
    editComment: 'Kommentar bearbeiten',
    editTemplate: 'Version bearbeiten',
    editUser: 'Benutzer bearbeiten',
    lockUser: 'Benutzer sperren',
    mfaEnforced: 'Wenn diese Einstellung deaktiviert ist, können Nutzer immer noch Zwei-Faktor-Authentifizierung nutzen. Sie können sie aber in ihren persönlichen Einstellungen wieder deaktivieren.',
    unlockUser: 'Benutzer entsperren',
    impersonateUser: 'Als Benutzer einloggen',
    deleteUser: 'Benutzer löschen',
    resendMail: 'Registrations-E-Mail erneut senden',
    deleteLink: 'Link löschen',
    editLink: 'Link bearbeiten',
    editLicense: 'Lizenzpaket bearbeiten',
    deleteLicense: 'Lizenzpaket löschen',
    restoreLicense: 'Lizenzpaket wiederherstellen',
    editAnnouncement: 'Mitteilung bearbeiten',
    deleteAnnouncement: 'Mitteilung löschen',
    pendingMutation: 'Adressänderung als geprüft markieren',
    search: 'Interview Fragen durchsuchen',
    searchInterviews: 'Interviews durchsuchen',
    uploadFiles: 'Gesetzesliste und Hilfe-Datei hochladen',
    filterShowNothing: 'Falls gar keine Antworten von diesem Filter erfasst werden, wird ein leerer Report generiert.',
    editCompanyName: 'Kundennamen bearbeiten',
    removeTwoFA: '2-Faktor Authetifizierung entfernen',
  },
  topic: {
    create: 'Neues Thema definieren',
    createText: 'Hier können Sie ein neues Thema definieren. Nach der Erstellung des Themas können Sie die erste Version des Themenbereichs anlegen.',
    label: 'Themenbereich|Themenbereiche',
    name: 'Bezeichnung',
    namePlaceholder: '01 Luft',
  },
  user: {
    delete: 'Account löschen',
    edit: {
      email: 'Bitte geben Sie die E-Mail-Adresse der Person an',
      firstName: 'Bitte geben Sie den Vornamen der Person an',
      lastName: 'Bitte geben Sie den Nachnamen der Person an',
      phone: 'Bitte geben Sie die Telefonnummer der Person an',
      position: 'Bitte geben Sie die Position der Person an',
      locale: 'Bitte geben Sie die Spracheinstellung der Person an',
    },
    email: {
      label: 'E-Mail',
    },
    firstName: {
      label: 'Vorname',
    },
    label: 'Nutzer|Nutzer',
    lastName: {
      label: 'Nachname',
    },
    lock: 'Account sperren',
    name: {
      label: 'Name|Namen',
    },
    new: {
      email: 'Bitte geben Sie die E-Mail-Adresse der neuen Person an',
      firstName: 'Bitte geben Sie den Vornamen der neuen Person an',
      lastName: 'Bitte geben Sie den Nachnamen der neuen Person an',
      name: 'Bitte geben Sie den Namen der neuen Person an',
      organizationalUnits: 'Zugriff auf folgende Standorte',
      phone: 'Bitte geben Sie die Telefonnummer der neuen Person an',
      position: 'Bitte geben Sie die Position der neuen Person an',
      locale: 'Bitte geben Sie die Spracheinstellung der neuen Person an',
      title: 'Neuen Nutzer hinzufügen',
    },
    noValidPhone: 'Ungültige Telefonnummer',
    password: {
      forgot: 'Passwort vergessen',
      label: 'Passwort',
      newPassword: {
        label: 'Neues Passwort|Neue Passwörter',
        labelRepeat: 'Neues Passwort wiederholen|Neue Passwörter wiederholen',
      },
      reset: {
        success: 'Wenn ein Konto mit der angegeben E-Mail-Adresse vorhanden ist, wird eine E-Mail mit weiteren Anweisungen versandt.',
        text: 'Geben Sie die E-Mail-Adresse Ihres Kontos ein, um das Passwort zurückzusetzen.',
        title: 'Passwort zurücksetzen',
      },
      setPasswordText: 'Um Ihr Konto zu schützen, stellen Sie sicher, dass Ihr Passwort mindestens 12 Zeichen lang und einzigartig ist.',
      setPasswordTitle: 'Passwort setzen',
    },
    phoneFormat: 'Geben Sie die Telefonnummer im Format 0041441234567 oder +41441234567 an.',
    resendRegistration: {
      helpText: 'Hier können Sie die Registrations-E-Mail, die auch den Link zum Setzen des Passworts enthält, erneut auslösen.',
      submit: 'Erneut senden',
      success: 'Die E-Mail wurde erneut versendet.',
      title: 'Registrations-E-Mail erneut senden',
    },
    roles: {
      label: 'Rolle|Rollen',
      ROLE_COMPANY_ADMIN: 'Administrator',
      ROLE_SUPER_ADMIN: 'Super-Administrator',
      ROLE_USER: 'Mitarbeiter',
    },
    settings: {
      title: 'Benutzereinstellungen',
    },
    unlock: 'Account entsperren',
    noValidEmail: 'Das ist keine gültige E-Mail-Adresse.',
  },
  violation: {
    cannotRead: 'Datei kann nicht gelesen werden',
    compromised: 'Ist bereits kompromittiert',
    email: 'Die E-Mail-Adresse ist ungültig',
    emailNotUnique: 'Diese E-Mail-Adresse ist bereits vergeben',
    intValue: 'Geben Sie eine Nummber zwischen 1 und 2,147,483,647 ein',
    invalidCountry: 'Dieses Land ist ungültig',
    invalidEmail: 'Diese E-Mail-Adresse ist ungültig',
    needLowercaseLetter: 'Keine Kleinbuchstaben vorhanden',
    needNumber: 'Keine Zahl vorhanden',
    needSpecialCharacter: 'Keine Spezialzeichen vorhanden',
    needUppercaseLetter: 'Keine Grossbuchstaben vorhanden',
    notAnImage: 'Die Datei ist keine gültige Bild-Datei',
    notBlank: '{0} darf nicht leer sein',
    notEmpty: 'Wählen Sie mindestens eine Option aus',
    notTheSame: 'Passwörter stimmen nicht überein',
    tooShort: 'Zu kurz (min. 12 Zeichen)',
    wrongFiletype: 'Die Datei ist im falschen Format. Nur JPG, PNG und GIF sind erlaubt.',
  },
  publicTaskView: {
    notAllowedToView: 'Diese Aufgabe wurde als abgeschlossen markiert. Sie können die Aufgabe nicht mehr bearbeiten.',
  },
};
