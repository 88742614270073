/* eslint-disable max-len */
export default {
  action: {
    add: 'Ajouter',
    addFile: 'Ajouter un fichier',
    addPath: 'Ajouter un chemin',
    archive: 'Archiver',
    back: 'Retour',
    cancel: 'Annuler',
    changePassword: 'changer le mot de passe',
    confirm: 'Confirmer',
    close: 'Fermer',
    continue: 'Continuer',
    create: 'Créer',
    delete: 'Supprimer',
    discard: 'Rejeter',
    edit: 'Éditer',
    editWhat: '{0} éditer',
    help: "Télécharger l'aide",
    irreversible: 'Cette action ne peut pas être annulée.',
    laws: 'Télécharger la liste des lois',
    lock: 'Verrouiller',
    login: 'Se connecter',
    logout: 'Se déconnecter',
    next: 'Continuer',
    postpone: 'Lire plus tard',
    publish: 'Publier',
    restore: 'Restaurer',
    resend: 'Envoyer un e-mail',
    save: 'Enregistrer',
    search: 'Recherche',
    selectAll: 'Sélectionner tout',
    selectNone: 'Tout désélectionner',
    start: 'Démarrer',
    takePicture: 'Prendre une photo',
    toLogin: 'Pour se connecter',
    transferAdmin: "Transférer les droits d'admin",
    unlock: 'Déverrouiller',
    uploadDragNDrop: 'Glisser-déposer de fichiers',
    uploadFile: 'Télécharger un fichier',
    uploadFiles: 'Télécharger des fichiers',
    uploadPath: 'Définir le chemin',
  },
  admin: {
    announcement: {
      add: 'Ajouter un message',
      delete: {
        title: 'Supprimer le message',
        text: 'Êtes-vous sûr de vouloir supprimer le message "{0}" du {1}?',
      },
    },
    dashboard: {
      allCustomers: 'Tous les clients',
      thisYear: 'Cette année',
      deletedCustomers: 'Clients supprimés cette année',
      lockedCustomers: 'Clients bloqués cette année',
      editedCustomers: 'Derniers clients mis à jour',
      newCustomers: 'Nouveaux clients de cette année',
      numbers: {
        customers: 'nombre de clients <small>(actifs, bloqués, supprimés)</small>',
        activeCustomers: 'nombre de clients actifs',
        label: 'nombre',
      },
      title: 'Tableau de bord - Admin',
    },
    interview: {
      delete: {
        content: 'Êtes-vous sûr de vouloir supprimer cette version du thème?',
        title: 'Supprimer la version de la release',
      },
      edit: {
        addLink: 'Ajouter un lien',
        deleteQuestion: 'Supprimer la question',
        deleteQuestionSure: 'Êtes-vous sûr de vouloir supprimer cette question?<br>Toutes les réponses des utilisateurs à cette question et donc toutes les tâches qui y sont liées seront également supprimées.',
        description: 'Commentaire',
        dragNDrop: "L'ordre peut être ajusté par glisser-déposer",
        inoperative: "N'est plus en vigueur",
        liveVersionWarning: 'Vous êtes en train de modifier la version live actuelle. Toutes les modifications sont immédiatement visibles pour tous les clients.',
        newQuestion: 'Nouvelle question',
        nodeType: {
          branch: "Question sur le fait d'être concerné",
          comment: 'Remarque',
          label: 'Types de réponses',
          question: 'Question sur le respect des exigences',
        },
        specialSettings: 'Propriétés spéciales',
        subtitle: 'Description',
        title: 'Titre',
      },
      editCurrentVersion: {
        content: 'Attention! Cette version du domaine thématique est dans la version actuelle. Si vous faites des modifications, elles seront immédiatement visibles pour tous les clients.',
        title: 'Modifier la version actuelle',
      },
      newVersion: {
        description: "Vous créez ici une nouvelle version du domaine thématique. L'ancienne version est copiée et peut être éditée par la suite.",
        label: 'Nom de la nouvelle version (par ex. v2)',
        title: 'Créer une nouvelle version',
      },
      publish: {
        title: 'Publier un thème',
        content: 'Ceci vous permet de publier un domaine thématique. Les nouvelles interviews de clients seront ensuite créées uniquement avec la version {0}.',
      },
    },
    lien: {
      add: 'Ajouter un lien',
      copied: 'lien copié dans le presse-papiers',
      created: 'Lien créé avec succès',
      export: 'Exporter des liens',
      directory: {
        add: 'Ajouter un dossier',
        allLinks: 'Tous les liens',
        create: 'Créer un répertoire de liens',
        created: 'Répertoire de liens créé avec succès',
        edit: 'Modifier le répertoire de liens',
        label: 'Répertoire de liens|Répertoires de liens',
        linksForDirectory: 'Lien dans le répertoire {0}',
        remove: 'Supprimer le répertoire de liens',
        removeConfirm: 'Etes-vous sûr de vouloir supprimer le répertoire "{0}"? ',
        removeWarn: '<strong> Tous les sous-répertoires et le lien dans le répertoire "{0}" seront également supprimés!</strong>',
        title: 'Titre',
        titleDe: 'Titre (allemand)',
        titleFr: 'Titre (français)',
      },
      deleted: 'Lien effacé avec succès',
      deleteText: 'Etes-vous sûr de vouloir supprimer le lien "{0}"?',
      deleteTitle: 'Supprimer le lien',
      edit: 'Modifier le lien',
      link: 'Lien',
      linkDe: 'Lien (allemand)',
      linkFr: 'Lien (français)',
      name: 'Nom',
      nameDe: 'Nom (allemand)',
      nameFr: 'Nom (français)',
      tags: 'Tags',
      title: 'Liens',
      updated: 'Lien modifié avec succès',
    },
    topic: {
      changeName: 'Modifier le nom',
      createdAt: 'Créé le',
      createdInterviewsCount: "Nombre total d'interviews créées",
      createdInterviewsYearCount: "Nombre d'interviews créées cette année",
      createNewVersion: 'Créer une nouvelle version',
      currentVersion: 'Version actuelle',
      customerStatistics: 'Statistiques du client',
      customersWithAccessCount: 'nombre de clients avec accès',
      draft: 'Brouillon',
      edit: 'Modifier',
      editComment: 'Modifier le commentaire',
      liveVersion: 'version live',
      name: 'nom',
      newVersionCreated: 'nouvelle version créée',
      overview: 'domaines thématiques',
      publish: 'Publier',
      updatedAt: 'mis à jour le',
      version: 'version',
      versionsOfTree: 'versions de ce domaine thématique',
    },
  },
  announcement: {
    activeFrom: 'Actif à partir de',
    author: 'Auteur',
    file: 'Fichier',
    label: 'Communiqué|Messages',
    readAt: 'Confirmé le',
    text: 'Texte (Français)',
    title: 'Titre',
    unread: 'Non lu',
  },
  answer: {
    question: {
      label: 'Question|Questions',
    },
  },
  company: {
    address: {
      contact: 'Adresse de contact',
      contactUpdated: 'adresse de contact mise à jour',
      copiedInvoiceToContact: 'adresse de contact reprise comme adresse de facturation',
      invoice: 'Adresse de facturation',
      invoiceUpdated: 'adresse de facturation mise à jour',
    },
    admin: {
      changeAdmin: "Transférer les droits d'admin",
      currentAdmin: 'Nouvel utilisateur principal',
      helpText: "Vous pouvez transférer les droits d'admin à un autre utilisateur. Veuillez noter que l'administrateur actuel perdra ses droits d'admin après l'exécution.",
      transferSuccess: "Les droits d'admin ont été transférés",
    },
    city: {
      label: 'Ville|Villes',
    },
    copyContactToInvoiceAddress: "Utiliser l'adresse de contact comme adresse de facturation",
    country: {
      label: 'Pays|Pays',
    },
    create: "Saisir le client et envoyer l'e-mail d'enregistrement",
    deleted: 'Ce client ainsi que ses données a été supprimé le {0} à {1}',
    deleteCompany: {
      delete: 'Supprimer le client',
      deleteHim: 'supprimer {0}',
      success: 'Le client est supprimé. Vous recevrez un e-mail dès que le processus de suppression sera terminé.',
      title: 'Supprimer le client',
      helpText: "La suppression d'un client entraîne également la suppression irréversible de toutes les données associées (interviews, réponses, tâches, utilisateurs).<br><br>Etes-vous sûr de vouloir effectuer cette opération?",
    },
    lockCompany: {
      lock: 'Bloquer un client',
      helpText: 'Si vous bloquez un client, tous les utilisateurs de ce client seront également bloqués.',
      unlock: 'Débloquer le client',
      unlocked: 'Le client a été réactivé',
      lockHim: '{0} Bloquer',
      lockSuccess: 'Le client a été bloqué',
      title: 'Bloquer le client',
    },
    deleteUser: {
      helpText: 'Si vous supprimez un utilisateur, vous pouvez transmettre les tâches qui lui ont été attribuées à un autre utilisateur.',
      submit: '{0} supprimer',
      success: "L'utilisateur a été supprimé",
      title: "Supprimer l'utilisateur",
    },
    editContactAddress: "Modifier l'adresse de contact",
    editData: 'Modifier les données de base',
    editName: 'Modifier le nom de la société',
    editInvoiceAddress: "Modifier l'adresse de facturation",
    email: {
      label: 'E-mail|E-mails',
    },
    general: {
      createdAt: 'Date de création',
      updatedAt: 'Modifié le',
      changeCompanyData: 'Adapter les données de base',
      changeContact: 'Modifier le contact',
      changeMasterUser: "Modifier l'utilisateur principal",
      companyData: 'données de base',
      contact: 'contact',
      createCustomer: 'Ajouter de nouveaux utilisateurs',
      editLicense: 'Personnaliser la licence',
      label: 'général',
      license: 'licence',
      masterUser: 'utilisateur principal',
      name: 'Nom de la société',
      newCompanyName: 'Veuillez indiquer le nom de la société',
      or: 'ou',
    },
    contactAddress: {
      email: {
        label: 'E-mail de l\'adresse de contact|E-mails de l\'adresse de contact',
      },
    },
    invoiceAddress: {
      email: {
        label: 'E-mail de facturation|E-mails de facturation',
      },
    },
    license: {
      availableLicenses: 'Licences disponibles',
      numberOfUser: 'Utilisateur enregistré / disponible',
      numberOfOus: 'Sites saisis / disponibles',
      telNumber: 'N° de téléphone',
      email: 'E-mail',
      changeHelpText: "Si la nouvelle licence comprend moins d'utilisateurs que l'ancienne, les utilisateurs seront bloqués",
      licensesAssigned: 'Licences attribuées',
      plan: 'Modèle de licence',
    },
    lockUser: {
      helpText: "Vous pouvez verrouiller un utilisateur. Un utilisateur verrouillé n'a pas accès à la plateforme. Si vous verrouillez un utilisateur, vous pouvez transférer les tâches attribuées à un autre utilisateur.",
      licenseContingentReached: "Le quota d'utilisateurs de la licence est épuisé",
      selectUser: 'Sélectionnez un utilisateur',
      submit: 'bloquer {0}',
      success: "L'utilisateur a été bloqué",
      title: "Bloquer l'utilisateur",
      transferTasksTo: 'Assigner des tâches',
    },
    overview: {
      allTopics: 'Tous les sujets (résumé)',
      label: 'Aperçu général',
      numberOfFinishedInterviews: 'Interviews terminées',
      totalNumberOfInterviews: 'Interviews réalisées',
    },
    pendingMutation: 'Mutation non vérifiée',
    postalCode: {
      label: 'Code postal',
    },
    showDeletedOnly: 'Afficher uniquement les clients supprimés',
    settings: {
      completeData: 'Veuillez compléter ici les données de votre entreprise. Vous pourrez ensuite commencer à utiliser COMPLYANT.',
      error: 'Une erreur est survenue. Veuillez réessayer plus tard',
      interviewAnswerCreateTaskWarning: 'Rappel de création de tâche en cas de questions non remplies',
      interviewAnswerSuggestClarificationTask: 'Afficher une remarque sur la création d\'une tâche lorsqu\'il est répondu à une question par "clarification détaillée".',
      interviewAnswerEmptyCommentWarning: 'Avertissement en cas de commentaire vide dans les questions',
      mfaEnforced: 'Forcer la mise en place de l\'authentification à deux facteurs pour tous les utilisateurs',
      mfaEnforcedAdminNeedsIt: "Vous devez d'abord activer l'authentification à deux facteurs dans votre profil avant de pouvoir la forcer à l'échelle de l'entreprise",
      sendTaskMails: 'Envoyer un e-mail lorsqu\'une tâche est créée ou qu\'une réponse est donnée',
      success: 'Le paramètre a été appliqué',
      title: 'Paramètres',
    },
    street: {
      label: 'Rue + N° de rue|Rue + N° de rue',
    },
    street2: {
      label: "Ligne d'adresse 2",
    },
    unlockDelete: 'Déverrouiller / Supprimer',
    user: {
      name: 'Nom',
      accessTo: 'Accès à',
      adminAccess: 'Tout le monde (administrateur)',
      allAccess: 'Tout le monde',
      label: 'utilisateur',
      locked: 'verrouillé',
    },
  },
  chart: {
    allQuestions: 'Toutes les questions',
    answeredQuestions: 'Questions auxquelles il a été répondu',
    'Nicht beantwortet': 'Non répondu',
    Beantwortet: 'Répondus',
    'Alle Fragen': 'Toutes les questions',
    'Alle Standorte': 'Tous les sites',
    'Beantwortete Fragen': 'Questions auxquelles il a été répondu',
    'Nicht relevant': 'Non pertinent',
    'Erfüllt - kein Handlungsbedarf': 'Satisfait - pas besoin d\'agir',
    'Erfüllt mit Auflagen': 'Satisfait avec conditions',
    'Detailabklärungen erforderlich': 'Clarifications de détail nécessaires',
    'Nicht erfüllt': 'Non rempli',
    notApplicable: 'n.a.',
  },
  customer: {
    company: {
      label: 'société',
      purgedAt: 'Supprimé le',
    },
    createTitle: 'Ajouter un client',
    descriptionText: 'Vous pouvez ajouter des clients ou personnaliser les rôles des utilisateurs existants.',
    exportTitle: 'Exporter la liste des clients',
    exportAllTitle: 'Exporter la liste des clients (tous les utilisateurs)',
    label: 'Client|Clients',
    licenseType: {
      label: 'Type de licence',
    },
    name: {
      label: 'Nom',
    },
    pendingMutation: {
      label: 'Nouvelles modifications',
    },
    updatedAt: {
      label: 'Dernière mise à jour',
    },
  },
  error: {
    account_locked: 'Votre compte a été bloqué. Veuillez contacter le support.',
    failedToCreateAnswer: 'Erreur lors de la préparation de la réponse. Veuillez recharger la page.',
    invalid_code: "Le code à deux facteurs n'est pas valide",
    invalid_credentials: "Données d'accès non valides",
    search: 'Erreur lors de la recherche. Veuillez réessayer',
    failedToSave: "Erreur lors de l'enregistrement: {0}",
    unknownError: "Une erreur inconnue s'est produite",
    unknownTryAgain: "Une erreur s'est produite. Veuillez réessayer plus tard",
    exceededNumberOfPrototypes: "Il ne peut y avoir qu'un seul modèle par site et par thème.",
    page_not_found: 'Cette page n\'existe pas.',
    export: "Erreur lors de l'exportation. Veuillez réessayer plus tard",
    fileTooLarge: 'Le fichier est trop volumineux. Veuillez sélectionner un fichier plus petit. (Max. 10MB)',
    fieldInUse: 'Actuellement en cours de traitement par un autre utilisateur!',
    recordInUse: 'Actuellement en cours de traitement par un autre utilisateur!',
  },
  export: {
    bouton: 'Exportation',
    loadingInfo: "L'exportation peut prendre quelques secondes, veuillez patienter.",
    chooseInterview: 'Quels sujets doivent être pris en compte?',
    chooseCopyInterview: 'Quels sujets doivent être copiés?',
    chooseOrganization: 'Veuillez sélectionner votre site',
    columns: {
      answer: 'Réponse',
      comment: 'Remarque plus',
      description: 'Commentaire',
      files: 'Fichiers',
      label: 'Colonnes',
      name: 'Nom',
      question: 'Question',
    },
    documentName: 'Nom du document',
    year: "Veuillez sélectionner l'année",
    topic: 'Veuillez sélectionner le domaine thématique',
    documentType: {
      label: 'Veuillez sélectionner le type de document',
      long: 'Justificatif - questions/réponses, y compris le texte de loi (PDF)',
      progress: 'Statut de conformité',
      short: 'Justificatif - questions/réponses uniquement (PDF)',
      interview: 'Justificatif - interviews non formatées (Excel)',
      links: 'Liens intégrés - liste (Excel)',
      documents: 'Liens propres - liste (Excel)',
    },
    exportType: {
      csv: 'En tant que fichier CSV (point-virgule)',
      excel: 'En tant que fichier Excel',
      label: 'Format de fichier',
      pdf: 'En tant que fichier PDF',
    },
    filter: {
      clarification: 'Clarification détaillée nécessaire',
      cond_fulfilled: 'Rempli - avec conditions',
      fulfilled: 'Rempli - aucune action requise',
      irrelevant: 'Non pertinent',
      label: 'Filtre',
      not_fulfilled: 'Non rempli',
      relevant: 'Toutes les questions / tous les points pertinents',
      new_updated: 'Nouvelles questions / questions mises à jour',
    },
    title: 'Exportation',
    progressOverview: 'Environnement + sécurité',
    interviewsNotFormatted: 'Entretiens non formatés',
    downloadName: 'Titre souhaité',
  },
  file: {
    label: 'Fichier|Fichiers',
    path: "Chemin d'accès au fichier",
  },
  global: {
    cancel: 'Annuler',
    createdAt: {
      label: 'Date de création|Dates de création',
    },
    delete: 'Supprimer irrémédiablement',
    deleted: 'Supprimé',
    deletedIt: '{0} a été supprimé',
    demoMode: 'Mode démo! Ne convient pas à une utilisation productive!',
    displayLanguage: "Langue d'affichage",
    imageFileFormat: 'Image au format JPEG, PNG ou GIF',
    impersonating: 'Vous êtes connecté en tant que {0} {1}',
    language: 'Langue',
    no: 'Non',
    none: 'Aucun',
    noResults: 'Aucun résultat',
    oclock: 'horloge',
    or: 'ou',
    simultaneousEditingWarning: 'Veuillez noter que l\'édition de la même interview au sein du même site par plusieurs utilisateurs en même temps n\'est pas supportée!',
    searchByFullName: 'Recherche de noms complets',
    stopImpersonating: "Retourner à l'admin",
    typeToSearch: 'Taper pour chercher',
    unknownError: 'Une erreur inconnue est survenue',
    updatedAt: {
      label: 'Mis à jour le',
    },
    yes: 'Oui',
    search: 'Rechercher',
    searchInterviews: 'Rechercher des interviews',
    languageValue: {
      de: 'Allemand',
      fr: 'Français',
    },
  },
  interview: {
    askForTask: {
      title: 'Créer une tâche?',
      text: 'Vous avez indiqué que des clarifications détaillées sont nécessaires. Voulez-vous créer directement une tâche?',
    },
    answer: {
      clarification: 'Clarification des détails',
      clarificationNeeded: 'Clarification détaillée nécessaire',
      comment: 'Remarque',
      conditionallyFulfilled: 'Rempli - avec conditions',
      containsNewQuestions: 'Contient de nouvelles questions',
      done: 'Fait',
      fulfilled: 'Rempli',
      fulfilledNoActionNeeded: 'Rempli - aucune action requise',
      irrelevant: 'Non pertinent',
      label: 'Réponse|Réponses',
      new: 'Nouveau',
      notFulfilled: 'Non rempli',
      open: 'Encore ouvert',
      yes: 'Oui',
      no: 'Non',
      noTooltip: 'Exportation pour le justicatif',
      questionNotRelevant: 'Question non pertinente',
      questionNotRelevantTooltip: "pas d'exportation dans le justicatif",
    },
    answerDegree: 'Niveau de réponse',
    branch: {
      label: 'Nœud|Nœuds',
    },
    bulkUpdate: 'Mettre à jour les interviews',
    bulkUpdateDescription: 'Souhaitez-vous mettre à jour les domaines thématique suivantes vers la dernière version?',
    comment: {
      label: 'Commentaire|Commentaires',
      showInReport: 'Afficher la note dans le rapport',
    },
    commentNotification: "Vous n'avez pas encore rédigé de commentaire. Veuillez rédiger une remarque ou cliquer à nouveau sur \"Suivant\".",
    copied: "Interview copié avec succès à l'emplacement {0}.",
    delete: {
      title: "Supprimer l'interview",
      text: "Souhaitez-vous vraiment supprimer l'interview?<br><strong>L'interview, toutes les réponses données et toutes les tâches associées sont <em>détruites de manière irréversible.</em>!</strong>",
    },
    degreeOfFulfillment: 'Degré de réalisation (questions auxquelles il a été répondu)',
    document: {
      label: 'Document|Documents',
    },
    filter: {
      clarification: 'Clarifications détaillées nécessaires',
      cond_fulfilled: 'Satisfait - sous conditions',
      fulfilled: 'Satisfait - aucune action nécessaire',
      highPriority: 'Haute priorité',
      inoperative: "Hors d'état",
      irrelevant: 'Non pertinent',
      not_fulfilled: 'Non satisfait',
      relevant: 'Questions pertinentes',
      notAnswered: 'Questions sans réponses',
      open: 'Pas de réponse',
      title: 'Possibilités de filtrage & visualisation',
      updated: 'Questions mises à jour',
      nothingFound: "Aucune question avec le filtre \"{0}\" n'a été trouvée.",
    },
    finished: 'Vous avez répondu à toutes les questions sélectionnées selon le filtre.',
    fromEmpty: 'Vide',
    fromPrevYear: 'De la version précédente',
    fromPrototype: 'A partir du modèle',
    fulfilmentState: 'État de réalisation',
    irrelevantParent: 'La question principale associée a été marquée comme non pertinente',
    label: "Nom de l'interview",
    name: {
      label: "Nom de l'interview",
    },
    newText: 'Veuillez sélectionner les sujets souhaités:',
    noPrevInterview: "Domaine thématique non rempli l'année précédente",
    noPrototypeInterview: "Aucun modèle n'est disponible",
    overview: "Aperçu de l'interview",
    question: {
      label: 'Question|Questions',
      updatedAt: 'Dernière modification',
    },
    state: {
      clarification: 'Clarifications détaillées',
      completed: 'Terminé',
      label: 'Statut de traitement',
    },
    gradeOfFulfillment: {
      label: 'Degré de conformité',
    },
    taskNotification: "Vous avez répondu \"Clarification détaillée nécessaire\". Voulez-vous créer une autre tâche? Si ce n'est pas le cas, cliquez à nouveau sur \"Suivant\".",
    templateForTopic: 'Domaine thématique',
    templateUpdatedAt: 'Dernière modification le',
    unansweredParent: "La question principale associée n'a pas encore reçu de réponse",
    version: 'Version de la version',
    year: 'Année',
    yearSelect: {
      label: "Sélection de l'année",
    },
    copyTo: 'Copier vers ...',
    copy: 'Copier',
    copyNoTopics: "Il n'y a pas de sujets à copier",
    update: 'Mise à jour de {0}',
    updateDescription: "Souhaitez-vous mettre à jour l'interview existante vers la version \"{0}\"?",
    updateSuccess: "L'interview a été mise à jour avec succès",
    updateError: "L'interview n'a pas pu être mise à jour",
    searchOnlyAnswers: 'Ne rechercher que ses propres réponses',
    enterSearch: 'Saisir le terme de recherche',
    search: "Rechercher les questions de l'interview",
    noResult: 'Aucun résultat',
    expandCollapseButton: 'Développer/réduire toutes les questions',
    copyDisabled: "L'interview n'est pas dans sa dernière version",
    exportDisabled: "L'interview n'est pas dans sa dernière version",
    prototypeTitle: 'Modèle pour',
  },
  license: {
    accessToTopics: 'Quels sujets doivent être inclus?',
    add: 'Ajouter un type de licence',
    allowedUsers: 'Utilisateurs # autorisés',
    allowedOus: 'Sites autorisés #',
    create: 'Créer une nouvelle licence',
    created: 'Une nouvelle licence a été créée',
    delete: {
      delete: 'Supprimer une licence',
      helpText: 'Une licence supprimée ne peut plus être ajoutée à un utilisateur',
      submit: '{0} supprimer',
      success: 'La licence a été supprimée',
      title: 'Supprimer la licence',
    },
    editNumberOfUsers: "Nombre d'utilisateurs autorisés",
    editNumberOfOrganizationalUnits: 'Nombre de sites autorisés',
    helpNegativeEqualsUnlimited: 'Une valeur de -1 signifie un nombre illimité',
    label: 'Licence|Licences',
    name: 'Nom',
    numberOfAssigned: 'nombre de clients attribués',
    topics: 'Sujets',
    updated: 'Licence mise à jour avec succès!',
  },
  login: {
    mfa: {
      description: "<p>Pour configurer l'authentification à deux facteurs, veuillez télécharger Google Authenticator ou une autre application TOTP sur votre smartphone. Pour ce faire, scannez le code QR suivant avec votre application.</p> ",
      descriptionGoogle: "<p>Recopiez maintenant le code à six chiffres de votre application d'authentification ici et cliquez sur \"Confirmer\".</p> ",
      success: "L'authentification à deux facteurs a été configurée avec succès! Vous pouvez maintenant vous connecter.",
      title: "Activer l'authentification à deux facteurs",
      titleGoogle: 'Google Authenticator 2FA Check',
      confirm: "Je confirme que j'ai ajouté 2FA à mon application Google Authenticator",
    },
  },
  network: {
    backOnline: 'Vous êtes à nouveau en ligne',
    offline: 'Sie sind offline',
  },
  onboarding: {
    title: 'Configuration initiale',
    prev: 'Précédent',
    next: 'Suivant',
    finish: 'Commencer à utiliser COMPLYANT',
    success: {
      title: 'Configuration terminée avec succès',
      text: "<p class=\"mb-4\">Cliquez sur \"Suivant\" ci-dessous pour accéder à la page d'accueil. Vous pouvez y créer de nouvelles interviews ou modifier celles qui existent déjà.</p > <p class=\"mb-4\">Vous accéderez désormais aux paramètres en cliquant sur l'icône en forme de roue dentée en haut à droite.",
    },
  },
  organizationalUnit: {
    label: 'Site|Sites',
    labelAccess: 'Accès à',
    name: {
      label: 'Nom',
    },
  },
  profile: {
    title: "Profil d'utilisateur",
    password: 'Mot de passe',
    passwordReset: 'Réinitialiser le mot de passe',
    passwordResetSuccess: 'Nous vous avons envoyé un e-mail pour réinitialiser votre mot de passe. Vous allez maintenant être déconnectée.',
    platformLanguage: 'Langue de la plateforme',
    resetTwoFactorSetup: "réinitialiser l'authentification à deux facteurs",
    role: 'Rôle',
    twoFactor: 'Authentification à deux facteurs',
    twoFactorReset: "Réinitialiser l'authentification à deux facteurs",
    twoFactorSetup: "Configurer l'authentification à deux facteurs",
    twoFactorText: "Ici, vous pouvez gérer l'authentification à deux facteurs. <br />Grâce à l'authentification à deux facteurs, vous pouvez sécuriser votre compte et le protéger contre les utilisations non autorisées.",
    mfa: {
      backToProfile: 'Retour au profil',
      code: 'Code 2FA',
      checkCode: 'Vérifier le code',
      disableButton: "désactiver l'authentification à deux facteurs",
      disable: 'Désactiver',
      intro: "Ici, vous pouvez installer l'authentification à deux facteurs.",
      introCompleted: "Vous avez déjà installé l'authentification à deux facteurs. Ici, vous pouvez réinitialiser l'authentification à deux facteurs et la configurer à nouveau.",
      introCompletedDisable: "Vous avez déjà configuré l'authentification à deux facteurs. Ici, vous pouvez réinitialiser l'authentification à deux facteurs et la configurer à nouveau ou la désactiver complètement.",
      loginWithCode: "Veuillez saisir le code de votre application d'authentification.",
      resetButton: "Configurer à nouveau l'authentification à deux facteurs",
      resetText: "Vous pouvez réinitialiser l'authentification à deux facteurs ici.<br><strong>Attention : toutes les applications d'authentification à deux facteurs configurées jusqu'à présent doivent être reconfigurées.!</strong>",
      disableText: "Vous pouvez désactiver l'authentification à deux facteurs ici. Pour ce faire, saisissez le code de votre application d'authentification. Si vous n'avez plus accès à votre code d'authentification, veuillez vous adresser à notre support.",
      scan: 'Veuillez ajouter COMPLYANT à votre Google Authenticator (ou autre app TOPT). Pour ce faire, scannez le code QR suivant avec votre application.<br>Saisissez ensuite le code à six chiffres affiché dans le champ.',
      setupButton: 'Configurer',
      success: "L'authentification à deux facteurs a été mise en place avec succès.",
      successDisabling: "L'authentification à deux facteurs a été désactivée.",
    },
  },
  question: {
    label: 'Question|Questions',
  },
  settings: {
    billing: {
      helpText: "Vous avez besoin d'autres licences? Dans ce cas, veuillez prendre contact avec nous.",
      overview: 'Aperçu',
      title: 'Informations sur la licence',
    },
    company: {
      created: 'Nouveau compte client créé.',
    },
    companyData: {
      title: 'Données de base',
    },
    import: {
      apiToken: 'Votre clé API:',
      checkForJobsText: 'Avez-vous déjà effectué une migration? Si oui, cliquez sur le bouton ci-dessous pour vérifier la migration',
      checkForJobs: 'Vérifier si la migration a été effectuée',
      downloadText: "Téléchargez ici l'assistant de migration. Celui-ci vous aidera à importer vos données de votre ancien système dans COMPLYANT",
      download: "Télécharger l'assistant de migration pour LCS.pro",
      downloadLcsNet: 'Télécharger l\'assistant de migration pour LCS.net',
      label: 'Importation',
      migrationDescription: 'Téléchargez l\'assistant de migration en cliquant sur le lien suivant.Après le téléchargement, vous devez décompresser l\'archive ZIP et exécuter le fichier "setup.exe".',
      migrationDescriptionNetwork: 'Le lien suivant vous permet de télécharger l\'assistant de migration pour LCS.net.Après le téléchargement, vous devez décompresser l\'archive ZIP et exécuter le fichier "setup.exe" sur un ordinateur ayant accès à la base de données LCS.net.',
      intro: "Avec l'aide de notre assistant de migration, vous pouvez facilement importer vos données de votre ancienne installation LCS.pro ou LCS.net dans COMPLYANT. Pour ce faire, téléchargez l'assistant de migration et suivez les instructions.",
      link: "Votre lien de téléchargement pour l'assistant de migration: ",
      skip: 'Démarrer avec COMPLYANT sans migration',
      onboarding: {
        download: "Vers l'assistant de migration",
        empty: 'Démarrer avec de nouvelles données',
        text: "Vous avez maintenant la possibilité d'importer des données existantes de LCS.pro ou LCS.net. Si vous préférez partir de zéro ou si vous n'avez rien à importer, vous pouvez aussi commencer avec de nouvelles données à la place.",
      },
    },
    organizationalUnits: {
      addUnit: 'Ajouter un site',
      created: 'Site créé avec succès',
      delete: 'Supprimer le site',
      deleted: 'Site supprimé avec succès',
      finishOnboarding: 'Sites saisis',
      intro: 'Vous pouvez ajouter de nouveaux sites ou adapter les sites existants.',
      introNoOu: 'Vous pouvez personnaliser les sites existants et adapter leur design spécifique.',
      new: 'Nouveau site',
      onboarding: "<p>Vous pouvez ici ajouter de nouveaux sites ou adapter des sites existants.</p><p>Vous souhaitez peut-être renommer le site initial? Pour ce faire, cliquez sur l'icône d'édition en bas du tableau.</p><p>Si les sites sont corrects, veuillez cliquer sur \"Sites saisis\".",
      sureToDelete: 'Êtes-vous sûr de vouloir supprimer ce site? <strong>Tous les entretiens, tâches et modèles liés à ce site seront également supprimés!</strong>',
      title: 'Sites',
      updated: 'Site mis à jour avec succès',
      saveTheme: 'schéma de couleurs mis à jour avec succès',
    },
    prototype: {
      chooseTopic: 'Veuillez sélectionner le thème souhaité',
      create: 'Créer un modèle',
      intro: "Vous pouvez créer de nouveaux modèles ou adapter des modèles existants. Il ne peut y avoir qu'un seul modèle par site et par thème.",
      label: 'Modèle|Modèles',
      organizationalUnits: "Le modèle s'applique aux sites suivants",
      delete: {
        title: 'Supprimer le modèle',
        text: 'Voulez-vous vraiment supprimer le modèle?',
      },
    },
    theme: {
      backgroundImage: "Image d'arrière-plan",
      backgroundImage_ou: 'Image d\'arrière-plan pour le site',
      colorFont: {
        label: 'Couleur de police',
      },
      colorMain: {
        label: 'Couleur principale',
        label_ou: 'Couleur principale pour le site',
      },
      colorSecondary: {
        label: 'Couleur secondaire',
      },
      enableOuSpecificTheme: 'Design spécifique au site',
      font: {
        label: 'Police de caractères',
      },
      intro: 'Vous pouvez personnaliser les éléments de la marque ainsi que le logo. Ceux-ci seront utilisés pour créer les fichiers PDF.',
      label: 'Design|Designs',
      logo: 'Logo',
      ouTitle: 'Design pour le site',
      saveText: 'Les modifications seront prises en compte dans tous les nouveaux fichiers PDF créés.',
      logo_ou: 'Logo pour le site',
      saved: 'Design enregistré avec succès',
    },
    userManagement: {
      addUser: 'Ajouter un utilisateur',
      created: 'Utilisateur créé avec succès',
      delete: 'Supprimer un utilisateur',
      deleted: 'Utilisateur supprimé avec succès',
      emailNotVerified: 'Pas encore confirmé',
      finishOnboarding: 'Vers les interviews',
      intro: 'Vous pouvez ajouter des utilisateurs ou personnaliser les rôles des utilisateurs existants',
      lockedUser: 'Utilisateur verrouillé avec succès',
      onboarding: 'Le départ est donné! Ici, vous pouvez encore saisir ou modifier vos utilisateurs. Lorsque vous avez terminé, vous pouvez vous rendre aux interviews et commencer à travailler correctement!',
      resetPassword: 'Réinitialiser le mot de passe',
      resetPasswordSuccess: 'e-mail de réinitialisation du mot de passe envoyé',
      sureToDelete: "Êtes-vous sûr de vouloir supprimer l'utilisateur \"{0}\"?",
      sureToResetPassword: "Êtes-vous sûr de vouloir lancer la procédure d'oubli du mot de passe pour l'utilisateur \"{0}\"? La personne recevra un e-mail lui indiquant la marche à suivre",
      sureToTransferAdmin: "Vos droits d'administrateur seront transférés à l'utilisateur \"{0}\" et vous seront retirés. Vous serez ensuite redirigé vers la page d'accueil et n'aurez plus aucun accès ici. Êtes-vous sûr de vouloir faire cela?",
      sureToTransferAdminAsPO: "Les droits d'admin sont transférés à l'utilisateur \"{0}\" et retirés à l'ancien admin. Les deux utilisateurs concernés seront informés de la modification par e-mail.",
      title: 'Gestion des utilisateurs',
      transferAdmin: "Transférer les droits d'administrateur",
      unlockedUser: 'utilisateur libéré avec succès',
      updated: 'utilisateur mis à jour avec succès',
      removeTwoFA: "Désactiver l'authentification à deux facteurs",
      transferAdminSuccess: "Droits d'administrateur transférés avec succès",
      removeTwoFASuccess: "Supprimée l'authentification à deux facteurs avec succès",
    },
  },
  task: {
    answer: 'Répondre',
    author: {
      label: 'Créé par',
    },
    copyAnswer: 'Copier la réponse dans la remarque de la question',
    createdAt: {
      label: 'Créé le',
    },
    export: {
      title: 'Exporter des tâches',
      fileName: 'Liste_des_taches.xlsx',
      assignee: 'Attribué à',
      topic: 'domaine thématique',
    },
    editTask: 'Modifier la tâche',
    archiveTask: 'Archiver la tâche',
    assignee: {
      formLabel: "Veuillez indiquer l'e-mail de la personne responsable",
      label: 'Personne compétente|Personnes compétentes',
      none: '-',
      noValidEmail: "Ceci n'est pas un e-mail valide",
    },
    createTitle: 'Créer une tâche',
    deleteTask: 'Supprimer la tâche',
    description: {
      label: 'Description|Descriptions',
      labelLong: 'Description de la tâche|Description de la tâche',
    },
    dueDate: {
      label: "Date d'échéance| Dates d'échéance",
      none: 'Aucune',
      warningLabel: "Date d'échéance",
      today: "Aujourd'hui",
    },
    includeArchived: 'Incl. tâches archivées',
    label: 'Tâche|Tâches',
    markCheck: 'Marquer pour vérification',
    markDone: 'Marquer comme terminé',
    name: {
      label: 'Nom de la tâche|Nom de la tâche',
    },
    navigateToQuestion: 'Vers la question',
    noneYet: "Aucune tâche pour l'instant",
    overview: 'Aperçu des tâches',
    recurring: {
      label: 'Récurrent',
      willRecur: "Sera copié dans l'itération de l'année suivante",
      helpText: "Les tâches récurrentes sont copiées et recréées lorsqu'une nouvelle interview est créée à partir des données de l'année précédente",
    },
    resolution: {
      label: 'Réponse|Réponses',
      none: 'Aucune',
    },
    sendEmail: { success: 'E-mail envoyé' },
    saved: 'La tâche a été sauvegardée',
    showDeletedTasks: 'Afficher les tâches archivées',
    showOwnTasksOnly: 'Afficher uniquement mes tâches',
    state: {
      check: 'Vérification',
      done: 'Terminé',
      label: ' Statut de traitement| Statut de traitement',
      new: 'Ouvert',
      notsent: 'Non envoyé',
      archived: 'Archivé',
    },
    sureToArchive: 'Êtes-vous sûr de vouloir archiver la tâche?',
    sureToDelete: 'Êtes-vous sûr de vouloir supprimer la tâche?',
    titleForQuestion: 'Tâches pour cette question',
    unarchive: "Annuler l'archivage",
    viewAllTasks: 'Voir toutes les tâches',
  },
  title: {
    adminDashboard: 'Tableau de bord',
    announcements: 'Annonces',
    customers: 'clients',
    interview: 'interview',
    interviews: 'interviews',
    interviewQuestions: 'domaine thématique',
    licenses: 'paquets de licences',
    links: 'liens',
    login: 'connexion',
    onboarding: 'onboarding',
    profile: 'profil',
    resetPassword: 'réinitialiser le mot de passe',
    setPassword: 'définir le mot de passe',
    settings: 'paramètres',
    tasks: 'tâches',
    template: 'Modifier le design',
    topic: 'thème',
    topicOverview: 'Espace thématique',
    totalOverview: "vue d'ensemble",
    userSettings: 'Paramètres utilisateur',
  },
  tooltip: {
    createInterview: 'Créer des interviews',
    interviewOverview: "Aperçu de l'interview",
    tasks: 'Tâches',
    announcements: 'Annonces',
    export: 'Exporter le statut de conformité et les interviews',
    exportCompanyOverview: 'Exportation',
    exportTasks: 'Exporter des tâches',
    markFavorite: 'Marquer la question comme importante',
    exportOne: "Exporter l'interview",
    updateInterview: "Mettre à jour l'interview vers une nouvelle version | Mettre à jour les interviews vers une nouvelle version",
    copy: "Copier les interviews vers d'autres sites",
    copyOne: "Copier l'interview vers d'autres sites",
    deleteInterview: "Supprimer l'interview",
    settings: 'Paramètres',
    customers: 'clients',
    links: 'Liens',
    licenses: 'Paquets de licences',
    deleteTemplate: 'Supprimer la version',
    editComment: 'Modifier le commentaire',
    editTemplate: 'Modifier la version',
    editUser: "Modifier l'utilisateur",
    lockUser: "Verrouiller l'utilisateur",
    mfaEnforced: 'Si ce paramètre est désactivé, les utilisateurs peuvent toujours utiliser l\'authentification 2FA.Ils peuvent toutefois le désactiver dans leurs paramètres personnels.',
    unlockUser: "Déverrouiller l'utilisateur",
    impersonateUser: "Se connecter en tant qu'utilisateur",
    deleteUser: 'supprimer un utilisateur',
    resendMail: "Renvoyer l'e-mail d'enregistrement",
    deleteLink: 'Supprimer le lien',
    editLink: 'Éditer le lien',
    editLicense: 'Modifier le package de licences',
    deleteLicense: 'Supprimer le package de licences',
    restoreLicense: 'Restaurer le package de licences',
    editAnnouncement: 'Modifier le message',
    deleteAnnouncement: 'Supprimer le message',
    pendingMutation: "Marquer le changement d'adresse comme vérifié",
    search: "Rechercher des questions d'interview",
    searchInterviews: 'Rechercher des interviews',
    uploadFiles: 'Télécharger la liste des lois et le fichier d\'aide',
    filterShowNothing: 'Si aucune réponse n\'est enregistrée par ce filtre, un rapport vide est généré.',
    editCompanyName: 'Modifer le nom du client',
  },
  topic: {
    create: 'Définir un nouveau sujet',
    createText: "Ici, vous pouvez définir un nouveau thème. Après avoir créé le thème, vous pouvez créer la première version de l'espace thématique.",
    label: 'Espace thématique|Espaces thématiques',
    name: 'Désignation',
    namePlaceholder: '01 air',
  },
  user: {
    delete: 'Supprimer le compte',
    edit: {
      email: "Veuillez indiquer l'e-mail de la personne",
      firstName: 'Veuillez indiquer le prénom de la personne',
      lastName: 'Veuillez indiquer le nom de famille de la personne',
      phone: 'Veuillez indiquer le numéro de téléphone de la personne',
      position: 'Veuillez indiquer la position de la personne',
      locale: 'Veuillez indiquer la langue de la personne',
    },
    email: {
      label: 'e-mail',
    },
    firstName: {
      label: 'Prénom',
    },
    label: 'Utilisateur|Utilisateur',
    lastName: {
      label: 'nom de famille',
    },
    lock: 'Bloquer le compte',
    nom: {
      label: 'Nom|Noms',
    },
    new: {
      email: "Veuillez indiquer l'e-mail de la nouvelle personne",
      firstName: 'Veuillez indiquer le prénom de la nouvelle personne',
      lastName: 'Veuillez indiquer le nom de famille de la nouvelle personne',
      name: 'Veuillez indiquer le nom de la nouvelle personne',
      organizationalUnits: 'Accès aux sites suivants',
      phone: 'Veuillez indiquer le numéro de téléphone de la nouvelle personne',
      position: 'Veuillez indiquer la position de la nouvelle personne',
      locale: 'Veuillez indiquer le paramètre de langue de la nouvelle personne',
      title: 'Ajouter un nouvel utilisateur',
    },
    noValidPhone: 'Numéro de téléphone non valide',
    password: {
      forgot: 'mot de passe oublié',
      label: 'mot de passe',
      newPassword: {
        label: 'Nouveau mot de passe|Nouveaux mots de passe',
        labelRepeat: 'Répéter le nouveau mot de passe|Répéter les nouveaux mots de passe',
      },
      reset: {
        success: "Si un compte existe avec l'e-mail spécifié, un e-mail sera envoyé avec des instructions supplémentaires",
        text: "Entrez l'adresse e-mail de votre compte pour réinitialiser le mot de passe",
        title: 'Réinitialiser le mot de passe',
      },
      setPasswordText: "Pour protéger votre compte, assurez-vous que votre mot de passe comporte au moins 12 caractères et qu'il est unique",
      setPasswordTitle: 'Réinitialiser le mot de passe',
    },
    phoneFormat: 'Indiquez le numéro de téléphone au format 0041441234567 ou +41441234567.',
    resendRegistration: {
      helpText: "Ici, vous pouvez relancer l'e-mail d'enregistrement, qui contient également le lien pour définir le mot de passe",
      submit: 'Envoyer à nouveau',
      success: "L'e-mail a été envoyé à nouveau",
      title: "Renvoyer l'e-mail d'enregistrement",
    },
    roles: {
      label: 'Rôle|Rôles',
      ROLE_COMPANY_ADMIN: 'Administrateur',
      ROLE_SUPER_ADMIN: 'super administrateur',
      ROLE_USER: 'Employé',
    },
    settings: {
      title: 'Paramètres utilisateur',
    },
    unlock: 'déverrouiller le compte',
    noValidEmail: "Ce n'est pas un e-mail valide",
  },
  violation: {
    cannotRead: 'Le fichier ne peut pas être lu',
    compromised: 'Est déjà compromis',
    email: "Cette adresse électronique n'est pas valide",
    emailNotUnique: 'Cette adresse e-mail est déjà utilisée',
    intValue: 'Saisissez un nombre compris entre 1 et 2,147,483,647',
    invalidCountry: "Ce pays n'est pas valide",
    invalidEmail: "Cette adresse e-mail n'est pas valide",
    needLowercaseLetter: "Aucune lettre minuscule n'est disponible",
    needNumber: 'Aucun chiffre disponible',
    needSpecialCharacter: 'Aucun caractère spécial disponible',
    needUppercaseLetter: 'Pas de majuscules disponibles',
    notAnImage: 'Le fichier n\'est pas un fichier image valide',
    notBlank: '{0} ne doit pas être vide',
    notEmpty: 'Sélectionnez au moins une option',
    notTheSame: 'Les mots de passe ne correspondent pas',
    tooShort: 'Trop court (min. 12 caractères)',
    wrongFiletype: 'Le fichier n\'est pas au bon format.Seuls les formats JPG, PNG et GIF sont autorisés.',
  },
  publicTaskView: {
    notAllowedToView: 'Cette tâche a été marquée comme terminée. Vous ne pouvez plus modifier cette tâche.',
  },
};
